import gql from 'graphql-tag';
import { useQuery } from 'urql';

import { useQueryParamsWithTime } from '@/hooks';
import { mapVariables } from '@/utils';
import { makePagination } from '@/utils/pagination';
import {
  ExpiredKycValidations,
  ExpiredKycValidationsVariables,
} from './__generated__/ExpiredKycValidations';
import { queryParams } from './queryParams';

const QUERY = gql`
  query ExpiredKycValidations(
    $aboutToExpireThresholdInDays: PositiveInt
    $after: String
    $before: String
    $countryCode: String
    $filterByAmlPercentageAbove: PositiveInt
    $first: Int
    $ignorePendingValidations: Boolean
    $last: Int
  ) {
    viewer {
      id
      expiredKycValidations(
        aboutToExpireThresholdInDays: $aboutToExpireThresholdInDays
        after: $after
        before: $before
        countryCode: $countryCode
        documentVerificationTypes: [Identity]
        filterByAmlPercentageAbove: $filterByAmlPercentageAbove
        first: $first
        ignorePendingValidations: $ignorePendingValidations
        last: $last
      ) {
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
        edges {
          node {
            playerId
            rawPlayerId
            countryCode
            email
            upToDateValidations
            aboutToExpireValidations
            expiredValidations
            pendingValidations
            pendingDocumentIds
            rejectedDocumentIds
          }
        }
      }
    }
  }
`;

export default function useExpiredKycValidations() {
  const [query, setQuery] = useQueryParamsWithTime(queryParams);

  const [{ data, fetching }, refresh] = useQuery<
    ExpiredKycValidations,
    ExpiredKycValidationsVariables
  >({
    query: QUERY,
    variables: mapVariables(query),
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => {
      setQuery(values, 'replaceIn');
    },
    ...makePagination(
      query,
      setQuery,
      data?.viewer.expiredKycValidations.pageInfo,
    ),
    validations: data?.viewer.expiredKycValidations?.edges?.map(
      (edge) => edge?.node,
    ),
  };
}
